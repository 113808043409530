@use "theme/variables" as *;

.c-dialog-offer {

    img.popup-image {
        max-height: 70vh;
        object-fit: contain;
        padding-bottom: $spacer-2;
        width: 100%;
    }

    p {
        font-size: $font-l;
        white-space: pre-wrap;
    }

    .modal-actions {
        display: flex;
        justify-content: space-between;
    }

    button {
        font-size: $font-l;
    }
}
