@use "theme/variables" as *;

.dashboard-list-image {
    border-radius: 50%;
    height: 50px;
    object-fit: contain;
    padding: 4px;
    width: 50px;
}

.text-light.specs {
    font-size: 11px;
}

td.price {
    font-size: 13px;
    width: 90px;
}

.c-widget-special-offers,
.c-widget-new-in-stock {

    tr:hover {
        background-color: #f9f9f9;
    }
}

.product-widget-item {

    .info {

        .image {
            aspect-ratio: 4 / 3;
            background: $grey-6;
            border: 1px solid $grey-5;
            box-shadow: $shadow-down-d;
            cursor: default;
            overflow: hidden;
            padding: $spacer-1 * 0.25;
            width: 100px;

            img {
                background: $white;
                border: 1px solid $grey-5;
                box-shadow: $shadow-down-d;
                height: 75px;
                object-fit: scale-down;
                width: auto;
            }

            .image-placeholder {
                height: 100%;
                padding: $spacer-1;
                width: 100%;
            }
        }
    }

    .description {
        flex: 1;
        overflow: hidden;
        padding: $spacer-1;


        .name {
            color: $info-1;
            font-weight: 600;
            width: 100%;

            &:hover {
                color: $brand-3;
            }
        }

        .arriving {
            font-style: italic;
        }
    }

    .price {
        align-items: center;
        display: flex;
        font-weight: 600;
        justify-content: flex-end;

        .c-changed {
            align-items: flex-end;
            flex-direction: column;
            gap: unset;

            .old-new {
                flex-direction: column;
            }
        }
    }

    .specs {
        color: $grey-3;
        font-size: $font-s;
    }

    &.carousel-active {

        .info {

            .image {
                cursor: zoom-in;
            }
        }
    }
}
