@use "theme/variables" as *;

.c-my-portal {

    h3 {
        margin-top: 0;
    }

    .form {
        max-width: 500px;
    }

    button {
        max-width: 500px;
    }
}
