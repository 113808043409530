@use "theme/variables" as *;

.c-product-availability {
    font-size: $font-xs;
    white-space: nowrap;

    .stock-cart {
        display: flex;
        font-size: $font-s;
        justify-content: flex-end;

        .moq,
        .in-stock,
        .in-cart {
            align-items: center;
            color: $default-2;
            cursor: help;
            display: flex;
            font-weight: 600;
            gap: $spacer-025;
            justify-content: flex-end;
            min-width: $spacer-3;
        }
    }

    .availability-conditions {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        .c-icon {
            margin-right: $spacer-05;
        }

        &.eta {

            &,
            .c-icon {
                color: $info-1;
            }
        }

        &.tbo {

            &,
            .c-icon {
                color: $default-2;
            }
        }

        &.purchase {
            color: $info-1;

            &,
            .c-icon {
                color: $info-1;
            }
        }

        &.stock {

            &,
            .c-icon {
                color: $success-1;
            }
        }
    }
}
