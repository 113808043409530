@use "theme/variables" as *;

.c-dialog.c-dialog-promotion {

    .dialog {
        max-height: unset;
        max-width: 1200px;
        position: relative;
        width: 100%;

        .body {
            padding: 0;
            position: relative;

            .c-carousel-giveaway {
                aspect-ratio: 12 / 8;

                .c-carousel-slides {
                    position: unset;
                }

                .slide-header {
                    display: none;
                }
            }

            .l-promotion {
                align-items: center;
                bottom: 0;
                display: flex;
                flex-direction: column;
                padding: $spacer-1;
                position: absolute;
                width: 100%;
                z-index: 100000;

                .wrapper {
                    backdrop-filter: blur(2px);
                    background: hsl(var(--grey-h) var(--s-0) var(--l-1) / 70%);
                    border: 1px solid $default-3;
                    border-radius: $spacer-2;
                    display: flex;
                    flex-direction: column;
                    max-width: 500px;
                    padding: $spacer-2;
                    width: 60%;

                    .promotion-text {
                        color: $grey-5;
                        font-size: $font-l;
                        line-height: 1.5rem;
                        margin-bottom: $spacer-2;
                    }

                    .c-promotion-terms {
                        align-self: flex-end;
                        color: $default-5;
                        font-weight: 600;
                        margin-top: $spacer-05;
                        padding: $spacer-05;
                        z-index: 1;

                        &:hover {
                            color: $brand-3;
                        }
                    }
                }

                .incentive-hurry {
                    color: $brand-3;
                    font-weight: 600;
                    margin-top: $spacer-1;
                }
            }
        }
    }

    @media (max-width: $breakpoint-20) {

        .dialog {
            display: flex;
            flex-direction: column;
            height: 100vh;
            width: 100%;

            .title {

                .title-text {
                    font-size: $font-l;
                }
            }

            .body {
                flex: 1;

                .l-promotion {

                    .wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }
}
