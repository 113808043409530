@use "theme/variables" as *;

.c-search-site {
    display: flex;
    flex: 1;
    z-index: 1000000000000;

    .search-box {
        align-items: center;
        display: flex;
        height: $spacer-6;
        position: relative;
        width: 100%;

        .btn {
            color: $default-4;

            &[disabled] {
                color: $grey-3;
            }

            &:not([disabled]):hover {
                color: $default-5;
            }
        }
    }

    .suggestions {
        background: $default-5;
        border-left: 0;
        border-top: 0;
        box-shadow: $shadow-down-s;
        cursor: default;
        display: flex;
        flex: 1;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 62px;
        transition: opacity 0.3s ease;
        visibility: hidden;

        .suggestion-type {
            display: flex;
            flex-direction: column;
            width: 350px;

            .search-header {
                align-items: center;
                background: $info-1;
                color: $default-5;
                display: flex;
                flex: 1;
                font-weight: 600;
                height: $spacer-6 + 2px;
                max-height: $spacer-6 + 2px;
                min-height: $spacer-6 + 2px;
                padding: $spacer-1;

                .tip {
                    align-items: center;
                    color: $default-4;
                    display: flex;
                    flex: 1;
                    font-size: $font-s;
                    font-style: italic;
                    justify-content: flex-end;
                    opacity: 0;
                    text-align: right;
                    transition: opacity 0.3s ease;

                    &.highlight {
                        color: $white;
                    }

                    &.active {
                        opacity: 1;
                    }

                    svg {
                        margin-right: $spacer-05;
                    }
                }
            }

            .suggestion-matches {
                // Space for 11 items of 40 px high. Uneven, because keeping
                // the active suggestion in the middle is only feasible with
                // uneven amount that fits in the viewport.
                max-height: 320px;
                overflow-y: scroll;
                position: relative;
                text-transform: capitalize;

                .suggestion {
                    // (!) Make sure pointer-events are none for all children,
                    // other the event delegate won't match the right selector
                    // to lookup the combined index.
                    align-items: center;
                    background: none;
                    border: none;
                    color: $info-1;
                    cursor: pointer;
                    display: flex;
                    height: $spacer-4;
                    padding: 0 $spacer-1;
                    text-transform: capitalize;
                    width: 100%;

                    .parent {
                        pointer-events: none;

                        &.no-children {
                            color: $info-1;
                        }

                        &.children {
                            color: $default-3;
                        }
                    }

                    .name {
                        display: inline-block;
                        overflow: hidden;
                        pointer-events: none;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &.selected {
                        background: $brand-4;
                        color: $default-5;

                        .parent {
                            color: $default-5;
                        }
                    }

                    &.active {
                        background: $brand-3;
                        color: $default-5;
                    }


                    &:hover {
                        background: $brand-3;
                        color: $default-5;
                    }
                }
            }
        }
    }

    &.active {

        .suggestions {
            opacity: 1;
            visibility: visible;
        }
    }

    @media (max-width: $breakpoint-20) {
        margin-right: 0;


        .suggestions {
            flex-direction: column;
            left: 0;
            max-height: calc(var(--app-height) - #{$spacer-6});
            width: 100%;

            .suggestion-type {
                width: 100%;

                .suggestion-matches {
                    max-height: 200px;
                }
            }
        }
    }
}
