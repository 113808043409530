@use "theme/variables" as *;

.c-my-portal-profile {

    .btn-removal {
        align-items: center;
        color: $danger-3;
        display: flex;
        flex: 1;
        font-weight: 600;
        justify-content: flex-end;
        padding: $spacer-1 0;
        width: 100%;
    }
}
