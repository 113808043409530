@use "theme/variables" as *;

.c-incentives-progress {

    .completed {
        align-items: center;
        display: flex;

        &.extended {
            background: $success-5;
            color: $success-1;
            padding: $spacer-1;

            .completed-text {
                flex: 1;
            }
        }
    }
}
