@use "theme/variables" as *;

.c-pricelist-download {


    button.btn-download {
        background-color: $brand-3;
        border: thick;
        color: $white;
        margin-left: 10px;


        &:hover {
            background: $brand-25;
        }
    }


    .dropdown-body {
        min-width: 250px;

        .selected-products {
            align-items: center;
            background: linear-gradient(to top, $grey-5, $grey-6);
            color: $grey-3;
            cursor: pointer;
            display: flex;
            font-style: italic;
            height: $spacer-6;
            padding-left: $spacer-1;

            svg {
                color: $grey-4;
                margin-right: $spacer-1;

                &:hover {
                    color: $grey-3;
                }
            }

            &:hover {
                color: $info-1;

                svg {
                    color: $info-3;
                }
            }
        }

        .options {
            padding: $spacer-1;
        }

        .btn-download {
            margin-top: $spacer-1;
        }
    }
}
