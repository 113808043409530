@use "theme/variables" as *;

.countdown {
    margin-top: 16px;
}

.countdown__time {
    border: 3px solid $white;
    color: $warning-3;
    display: inline-block;
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.countdown__catch {
    font-size: 14pt;
    font-weight: bold;
}

.countdown__unsold {
    font-size: 10pt;
    font-style: italic;
    font-weight: normal;
}
