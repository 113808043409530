@use "theme/variables" as *;

.c-incentive {
    align-items: center;
    border-bottom: 2px solid;
    display: flex;
    flex-direction: row;
    padding: $spacer-1;
    position: relative;
    width: 100%;

    &.promotion {
        background: $success-5;
        border-color: $success-2;

        .incentive-header {
            color: $default-2;

            &:hover {
                color: $success-2;
            }
        }

        .text {
            color: $success-1;
            flex: 1;
        }
    }

    &.transport {
        background: $info-5;
        border-color: $info-2;

        .incentive-header {
            color: $info-1;
        }

        .text {
            color: $info-1;
        }
    }

    .incentive-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: $spacer-1;
    }
}

.incentives {
    display: flex;
    flex-direction: column;
    width: 100%;
}

