@use "theme/variables" as *;

.c-collection-view {

    .c-product-image {
        aspect-ratio: 4 / 3;
        background: $default-4;
        color: $grey-1;
        overflow: hidden; // hide the ribbon ends
        position: relative;
        width: 100%;

        &.carousel-active {
            cursor: zoom-in;
        }

        img {
            height: 100%;
            object-fit: fill;
            padding: $spacer-05;
            width: 100%;
        }

        .image-placeholder {
            height: 100%;
            padding: 10%;
            width: 100%;
        }

        .c-offer-price-ribbon {
            align-items: center;
            background: $brand-3;
            color: $brand-6;
            display: flex;
            flex-direction: column;
            font-size: $font-xs;
            font-weight: 600;
            justify-content: center;
            left: -30px;
            padding: $spacer-05;
            position: absolute;
            top: 25px;
            transform: rotate(-45deg);
            width: 140px;
        }

        .c-favourites {
            background: hsla(var(--default-h) var(--s-1) 100% / 70%);
            border: 1px solid $grey-3;
            border-radius: 50%;
            bottom: $spacer-1;
            cursor: pointer;
            height: $spacer-3;
            padding: $spacer-05;
            position: absolute;
            transition: all 0.3s ease;
            width: $spacer-3;

            svg {
                color: $grey-3;
                height: 20px;
                width: 20px;
            }

            &:hover,
            &.active {
                border-color: $brand-3;
                color: $brand-3;
            }
        }
    }

    &.grid {

        .c-product-image {

            .c-item-tags {
                flex-direction: column;
                position: absolute;
                right: $spacer-1;
                top: $spacer-1;
            }

            .c-favourites {
                bottom: $spacer-1 * 0.75;
                position: absolute;
                right: $spacer-1 * 0.75;
            }
        }
    }

    &.list {

        .c-product-image {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .c-item-tags {
                bottom: $spacer-1;
                flex-direction: column;
                left: $spacer-1;
                position: absolute;
            }

            .c-favourites {
                bottom: $spacer-1 * 0.75;
                position: absolute;
                right: $spacer-1 * 0.75;
            }
        }
    }
}
