@use "theme/variables" as *;

.c-collection-view {

    &.loading {

        .c-collection-product .image,
        .c-collection-product .container {
            opacity: 0.25;
        }
    }

    .c-collection-product {
        align-items: center;
        animation: fade-in 0.5s ease-in-out;
        box-shadow: $shadow-down-l;
        display: grid;
        position: relative;

        &::after {
            box-shadow: $shadow-down-d-l;
            content: "";
            height: 100%;
            opacity: 0;
            position: absolute;
            transition: opacity 0.3s ease-in-out;
            width: 100%;
            z-index: -1;
        }

        .select-toggle {
            background: $surface-5;
            border: 1px solid $surface-4;
            border-radius: $spacer-05;
            padding: $spacer-05;
        }

        &:hover {

            &::after {
                opacity: 1;
            }
        }

        .c-checkbox {
            margin-bottom: 0;
        }

        &:nth-of-type(odd) {

            &:hover {
                background: $default-5;

                .c-product-info {
                    background: $default-4;
                }
            }
        }

        &:nth-of-type(even) {

            &:hover {
                background: $info-5;

                .c-product-info {
                    background: $info-4;
                }
            }

            .c-product-specs {

                .c-data-card {

                    > :nth-child(odd) {
                        background: $info-5;
                    }
                }
            }
        }

        &.has-cart-actions {

            .cart-actions {
                pointer-events: none;
                position: absolute;
                z-index: 1000;

                .action-toggles {
                    background: hsla(var(--info-h) var(--s-2) var(--l-1) / 50%);
                    display: flex;
                    padding: $spacer-05;
                    pointer-events: auto;
                }

                .action-body {
                    background: $white;
                    display: flex;
                    pointer-events: auto;

                    .action-title {
                        align-items: center;
                        color: $info-1;
                        display: flex;
                        font-weight: 600;
                        margin-bottom: $spacer-05;
                    }

                    .action-content {
                        display: flex;
                    }
                }
            }
        }

        @media (max-width: $breakpoint-20) {

            .c-product-image {
                display: none;
            }
        }
    }

    .c-product-specs .product-name {
        gap: $spacer-025;
    }

    &.grid {

        .c-collection-product {
            display: flex;
            flex-direction: column;
            position: relative;  // for absolute positioned item-tags

            &.has-cart-actions {

                .cart-actions {
                    flex-direction: row;
                    left: 0;
                    top: 0;
                    width: 100%;

                    .action-toggles {
                        // background: #00f;
                    }

                    .action-body {
                        flex: 1;
                        flex-direction: column;
                        height: 100%;
                        padding: $spacer-1;

                        .action-content {
                            align-items: flex-end;
                            flex: 1;
                            flex-direction: column;
                            width: 100%;

                            .c-field-textarea {
                                height: 100%;
                                width: 100%;

                                textarea {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .select-toggle {
                left: 0;
                position: absolute;
                top: 0;
                z-index: 1; // create new stacking context
            }
        }
    }

    &.list {

        .c-collection-product {
            align-items: center;

            &.has-cart-actions {

                .cart-actions {
                    display: flex;
                    height: 100%;
                    width: 100%;

                    .action-toggles {
                        flex-direction: column;
                        width: $spacer-4;
                    }

                    .action-body {
                        flex: 1;
                        flex-direction: column;
                        padding-left: $spacer-1;
                        padding-right: $spacer-1;
                        padding-top: $spacer-1;

                        .action-content {
                            flex-direction: column;

                            .c-field-textarea {
                                flex: 1;
                                margin: 0;
                                margin-bottom: $spacer-1;

                                textarea {
                                    min-height: 70px;
                                }
                            }

                            .action-controls {
                                display: flex;
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }

            .content-wrapper {
                height: 100%;
                width: 100%;

                .container {
                    flex: 1;
                    height: 100%;
                    width: 100%;
                }
            }

            .select-toggle {
                left: 0;
                position: absolute;
                top: 0;
                z-index: 1; // create new stacking context
            }
        }
    }

    @media (min-width: $breakpoint-50) {

        &.list {

            .c-collection-product {
                grid-template-columns: 250px 1fr 148px;
            }
        }
    }

    @media (max-width: $breakpoint-50) {

        &.list {

            .c-collection-product {
                grid-template-columns: 220px 1fr 148px;
            }
        }
    }

    @media (max-width: $breakpoint-40) {

        &.list {

            .c-collection-product {
                grid-template-columns: 200px 1fr 148px;
            }
        }
    }
}
