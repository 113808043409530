@use "theme/variables" as *;

.c-order-view {

    .c-order-congratulations {
        max-width: 600px;

        .rating {
            align-items: center;
            background: $default-5;
            border: 1px solid $default-4;
            border-radius: 0;
            margin-bottom: $spacer-3;
            margin-top: $spacer-3;
            padding: $spacer-2;
        }
    }

    .c-items-view {

        .row {

            .column {
                padding-bottom: 0;
                padding-top: 0;

                &.column-product {
                    flex: 3;
                }

                &.column-quantity {


                    .total-quantity {
                        align-items: center;
                        display: flex;
                        gap: $spacer-05;
                    }
                }


                &.column-subtotal {
                    flex: 1;

                    .vat {
                        display: flex;
                        gap: $spacer-1;
                        white-space: nowrap;
                    }

                    .c-amount {
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.order-alert {
    align-items: center;
    background: $default-5;
    border: 2px solid $default-4;
    border-radius: 0;
    display: flex;
    max-width: 450px;

    .promotion-reminder {
        background: $success-1;
        border: 2px solid $success-2;
        max-width: 450px;
        padding: $spacer-1;

        .description {
            color: $success-5;
        }
    }

    > .c-icon {
        color: $info-3;
        height: $icon-xxl;
        margin-right: $spacer-2;
        width: $icon-xxl;
    }

    .order-text {
        flex: 1;
        max-width: 500px;

        .title {
            font-size: $font-l;
            margin-bottom: $spacer-1;
        }

        .subtitle {
            font-size: $font-l;
            margin-bottom: $spacer-1;
        }

        > .description {
            color: $grey-1;
        }
    }
}
