@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.c-dashboard {
    max-height: calc(var(--app-height) - var(--header-size));
    overflow-y: scroll;

    .view {
        transition: max-height 0.3s ease;
    }

    .highlight-title {
        color: $info-2;
        font-size: $font-xxl;
        margin: $spacer-2 0;
        padding-bottom: $spacer-1;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &::after {
            background-color: $info-2;
            bottom: -4px;
            content: "";
            height: 2px;
            left: calc((100% - 125px) / 2);
            position: absolute;
            right: calc((100% - 125px) / 2);
        }
    }

    .l-widget-row {
        display: flex;
        flex-wrap: wrap;
        gap: $spacer-4;
        margin-bottom: $spacer-6;
        max-width: 100%;

        // Unclear why this would trigger an overflow on
        // the .c-dashboard .view container otherwise
        overflow: hidden;

        &.row-2 {

            .c-widget-latest-orders,
            .c-widget-offers,
            .l-widget-column {
                flex: 1;
            }
        }

        .c-widget {
            flex: 1;
            margin-bottom: $spacer-2;
            width: 100%;

            &.no-border {
                border: 0;
            }

            .c-widget-title {
                align-items: center;
                background: $info-5;
                color: $info-1;
                display: flex;
                font-size: $font-l;
                gap: $spacer-05;
                height: $spacer-6;
                line-height: 40px;
                padding: 0 $spacer-1;
                vertical-align: middle;
            }

            .c-widget-body {

                .loading {
                    align-items: center;
                    color: $grey-3;
                    display: flex;
                    justify-content: center;
                    min-height: 200px;
                    padding: $spacer-1;

                    svg {
                        @include square($icon-xl);

                        color: $brand-3;
                    }
                }
            }

            .c-empty-message {
                font-style: italic;
                font-weight: 600;
                margin: $spacer-2 $spacer-1;
                padding: $spacer-1;
            }

            .price {
                font-size: $font-s;
                text-align: right;
            }

            .c-widget-footer {
                padding: $spacer-1;

                a {
                    color: $default-3;
                    display: inline-block;

                    &:hover {
                        color: $info-2;
                    }
                }
            }

            table {
                margin-bottom: 12px;
                padding: 0;

                td {
                    height: 80px;
                    max-width: 300px;
                    padding: 6px;
                    vertical-align: top;
                    vertical-align: middle;

                    &.thumb {
                        border: 0;
                        padding: 0;
                        width: 60px;
                    }
                }
            }

            .quick-action {
                align-items: center;
                background-color: $grey-5;
                border: 1px solid $grey-4;
                border-radius: $spacer-4;
                color: $grey-2;
                display: flex;
                flex-direction: column;
                flex-direction: row;
                height: 100%;
                justify-content: center;
                max-width: 600px;
                min-height: 150px;
                text-decoration: none;
                transition: background-color 0.5s, color 0.3s;
                width: 100%;

                &[disabled] {
                    background-color: $grey-6;
                    border: 1px solid $grey-5;
                    color: $grey-4;

                    &:hover {
                        cursor: not-allowed;
                    }
                }

                &:not([disabled]) {

                    &:hover {
                        background-color: $info-3;
                        color: $grey-5;
                    }
                }

                .icon {
                    font-size: 62px;
                    padding-left: 20px;
                }

                .text {
                    font-family: monospace;
                    font-size: 18px;
                    font-weight: 700;
                    padding-left: 20px;
                }


                &.promotion {
                    background-color: #cc0;
                    box-shadow: inset 0 3px 8px 0 rgb(0 0 0 / 10%);
                    color: $white;
                    flex-direction: row;
                    transition: background-color 0.5s, box-shadow 0.3s;

                    &:hover {
                        background-color: #dd0;
                        box-shadow: inset 0 0 0 0 rgb(0 0 0 / 10%);
                    }

                    &:active {
                        box-shadow: inset 0 3px 8px 0 rgb(0 0 0 / 10%);
                        transition: box-shadow 0.1s;
                    }

                    .icon {
                        padding-left: 20px;
                    }

                    .text {
                        font-size: 18px;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}

.widget-loading {
    align-items: center;
    color: $grey-3;
    display: flex;
    justify-content: center;
    padding: $spacer-1;

    &.placeholder-d {
        min-height: 250px;
    }

    &.placeholder-s {
        min-height: 120px;
    }

    svg {
        @include square($icon-xl);

        color: $brand-3;
    }
}

.widget-list {

    .widget-item {
        cursor: pointer;
        display: flex;
        height: 100px;
        padding: $spacer-1;
        transition: visibility 0.3s linear, opacity 0.3s linear;

        &:nth-child(even) {
            background: $grey-6;
        }

        &:hover {
            background: $default-5;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .description {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .actions {
            align-items: center;
            display: flex;
            gap: $spacer-1;
        }
    }
}


@media (max-width: $breakpoint-35) {

    .l-widget-row {
        flex-direction: column;
    }
}

@media (max-width: $breakpoint-20) {

    .c-dashboard {

        .c-spotlight-widget {

            .items {

                .item {

                    .product-image-container {
                        height: 100px;
                        width: 100px;
                    }
                }
            }
        }

        .l-favourites-widget {
            // Favorites carousel is not usable on mobile.
            display: none;
        }
    }
}
