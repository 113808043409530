@use "theme/variables" as *;

.c-collection-view {

    .c-product-info {
        display: flex;
        overflow: hidden;

        .pricing {
            flex-direction: column;

            .price-main {
                align-items: center;
                color: $surface-1;
                display: flex;
                font-size: $font-l;
            }

            .validity-date {
                color: $grey-3;
                font-size: $font-l;
                font-style: italic;
                font-weight: 600;
            }

            .price-detail {
                display: flex;
                font-size: $font-xs;
                gap: $spacer-05;
            }

            .price-cart {
                font-size: $font-xs;
            }

            &.warning {

                .old-new {
                    color: $warning-3;
                }
            }

            &.success {

                .old-new {
                    color: $success-2;
                }
            }
        }

        .excise {
            color: $surface-2;
            display: flex;
            flex-direction: column;
            font-size: $font-xxs;
            white-space: nowrap;
            width: 100%;
        }

        &.variant-booked {

            .c-order-quantity {
                display: none;
            }

            .in-stock {
                display: none;
            }
        }
    }

    .c-offer-item-amount {
        font-size: $font-xl;
        font-weight: 500;
    }

    .c-changed {
        align-items: flex-end;

        .previous {
            font-size: $font-d;
        }

        .current {
            font-size: $font-xl;
        }
    }

    .c-collection-product {

        &:nth-of-type(odd) {
            background: $surface-6;

            .c-product-info {
                background: $surface-5;
            }

            &:hover {

                .container {
                    background: $brand-5;
                }
            }
        }

        &:nth-of-type(even) {
            background: $info-6;

            .c-product-info {
                background: $info-5;
            }

            &:hover {

                .container {
                    background: $brand-5;
                }
            }
        }
    }

    &.grid {

        .c-product-info {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center !important;
            padding: $spacer-1;
            padding-bottom: $spacer-4;
            width: 100%;

            @media (max-width: $breakpoint-10) {
                padding: $spacer-05;
            }

            .stock-cart {
                bottom: 0;
                padding: $spacer-05 $spacer-1;
                position: absolute;
                right: 0;
                width: 100%;
            }

            .pricing {
                display: flex;
                height: 100%;
                justify-content: center;

                .c-changed {
                    align-items: flex-start;
                }
            }

            .mode-wrapper {
                flex-direction: column-reverse;
                padding: 0;
                width: 100%;
            }
        }
    }

    &.list {

        .c-product-info {
            align-items: flex-end;
            background: #f00;
            flex-direction: column;
            height: 100%;

            // min-width: 350px;
            padding: $spacer-05;


            .excise {
                align-items: flex-end;
            }

            .pricing {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                font-size: $font-l;
                justify-content: center;

                .c-changed {
                    text-align: right;

                    .validity {
                        text-align: right;
                    }
                }
            }

            .mode-wrapper {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: flex-end;
                justify-items: flex-end;
            }
        }
    }
}

