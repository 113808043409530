@use "theme/variables" as *;

.c-breadcrumbs {
    display: flex;
    height: $spacer-4;
    margin-bottom: $spacer-1;

    .breadcrumb,
    svg {
        height: $spacer-4;
    }

    a.breadcrumb {
        cursor: pointer;

        &:hover {
            color: $default-2;
        }

        &.current {

            &:hover {
                color: $brand-3;
            }
        }
    }

    .breadcrumb {
        align-items: center;
        color: $default-3;
        display: flex;
        text-transform: uppercase;

        &.current {
            color: $info-1;
        }
    }
}
