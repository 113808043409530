@use "theme/variables" as *;

.c-collection-view {

    .c-product-specs {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        padding: $spacer-1;
        width: 100%;

        @media (max-width: $breakpoint-10) {
            padding: $spacer-05;
        }

        .product-name {
            align-items: center;
            color: $info-1;
            display: flex;
            font-weight: 500;
            height: $spacer-3;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
                color: $info-1;
                flex: 1;
                overflow-x: hidden;
                padding: $spacer-05 0;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
