@use "theme/variables" as *;

.c-registration {

    .c-stepper {

        .steps {

            .progress-line {
                background: $success-4;
            }
        }
    }

    .consent {
        margin-top: $spacer-2;


        .checkbox {
            line-height: $spacer-2;
            margin-bottom: $spacer-05;

            label {
                align-items: center;
                display: flex;
            }
        }
    }

    .team-regards {
        color: $info-1;
        font-size: $font-l;
        margin-bottom: $spacer-2;
        position: relative;

        img {
            box-shadow: $shadow-down-d;
            max-width: 100%;
        }

        span {
            background: $default-5;
            bottom: 0;
            color: $brand-3;
            left: 0;
            padding: $spacer-05;
            padding-left: 0;
            position: absolute;
        }
    }

    p {
        line-height: 1.5rem;
        margin-bottom: 0;
    }
}
