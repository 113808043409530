@use "theme/variables" as *;

.c-widget-latest-orders {

    .stock-status {
        align-items: center;
        display: flex;
        z-index: 100;
    }
}
