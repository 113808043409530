@use "theme/variables" as *;

.c-widget {

    .widget-list {

        img {
            animation: fade 800ms ease-in;
        }
    }
}

@keyframes fade {

    0% {
        opacity: 0.05;
    }

    100% {
        opacity: 1;
    }
}
