@use "theme/variables" as *;

.c-total-costs {
    background: $info-6;
    border-bottom: 1px solid $default-4;
    border-top: 1px solid $default-4;
    display: flex;
    flex-direction: column;
    width: 100%;

    hr {
        border-bottom: 1px solid $default-4;
        margin: 0;
    }

    .subtotal {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $spacer-1 * 1.5;

        .header {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: space-between;

            .description {
                align-items: center;
                display: flex;

                svg {
                    color: $info-1;
                    margin-right: $spacer-05;
                }
            }

            .amount {
                align-items: center;
                color: $default-2;
                display: flex;
                font-weight: 600;
            }
        }

        .details {
            color: $info-1;
            display: flex;
            flex: 1;
            font-size: $font-xs;
            gap: $spacer-2;
            justify-content: flex-end;
            width: 100%;

            .detail {
                align-items: flex-start;
                display: flex;
                gap: $spacer-05;
                justify-content: flex-end;
            }
        }
    }

    &.collapsed {

        .subtotal {
            margin: 0;
            padding: 0 $spacer-1;

            .header {
                align-items: center;
                flex-direction: column;

                .description {

                    svg {
                        margin-right: 0;
                    }

                    span {
                        display: none;
                    }
                }

                .details {
                    display: none;
                }
            }
        }

        .details {
            display: none;
        }
    }
}
