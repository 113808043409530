@use "theme/variables" as *;

.c-messroom {
    align-items: center;
    background-color: $black;
    background-image: url("/common/img/landing-messroom.jpg");
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;

    .messroom-dialog {
        backdrop-filter: blur(4px);
        background: hsla(var(--info-h) var(--s-1) var(--l-1) / 70%);
        border-radius: $border-radius-d;
        color: $white;
        max-width: 400px;
        padding: $spacer-2;

        .title {
            align-items: center;
            color: $info-3;
            display: flex;
            font-size: $font-l;
            gap: $spacer-1;
            margin-bottom: $spacer-1;
        }

        .description {
            margin-bottom: $spacer-1;
        }

        .team {
            color: $info-3;
            font-weight: 600;
        }

        .steps {
            margin: $spacer-2 0;

            .step {
                align-items: center;
                display: flex;
                gap: $spacer-1;
                height: $spacer-3;

                .c-icon {
                    margin-right: 0;
                }

                &.active {
                    color: $info-3;
                }
            }
        }

        .steps-status {
            align-items: center;
            display: flex;
            gap: $spacer-1;

            &.completed {
                color: $info-3;
            }
        }
    }
}
