@use "theme/variables" as *;

.c-my-portal-privacy {

    .text-container {
        background: $white;
        box-shadow: $shadow-down-d;
        max-width: 900px;
        padding: $spacer-2;
    }

    .title {
        margin-top: 0;
    }

    ul {
        list-style-position: inside;
        list-style-type: initial;
        padding-left: $spacer-1;
    }
}

.c-layout-landing {

    .c-my-portal-privacy {
        background: $white;
        border-radius: $spacer-2;
        margin-bottom: $spacer-2;
        max-height: 400px;
        max-width: 470px;
        overflow-y: scroll;

        .text-container {
            background: none;
            box-shadow: none;

            // margin: 0;
            overflow-y: scroll;
            padding: $spacer-2;
        }
    }
}
