@use "theme/variables" as *;

.c-field-token-items {
    display: flex;
    flex-direction: column;
    gap: $spacer-05;
    margin-top: $spacer-1;

    .collected {
        background: $default-5;
        border: 1px solid $default-4;
        display: flex;
        padding: $spacer-1;

        .description {
            align-items: center;
            display: flex;
            flex: 1;
            font-weight: 600;
            text-transform: uppercase;
        }

        .token-stats {
            align-items: center;
            display: flex;
            margin-right: $spacer-1;

            .token-summarize,
            .token {

                &.draft {
                    color: $default-3;
                }

                &.redeemable {
                    color: $default-2;
                }

                &.selected {
                    color: $success-2;
                }
            }

            .token-summarize {
                position: relative;

                .amount {
                    bottom: -1 * $spacer-1;

                    // color: $default-3;
                    font-size: $font-s;
                    font-weight: 600;
                    position: absolute;
                    right: 0;
                    z-index: 100;
                }
            }
        }
    }

    .token-item-wrapper {
        background: $default-5;
        border: 1px solid $default-4;
        padding: $spacer-1;

        .gifts-label {
            align-items: center;
            color: $default-2;
            display: flex;
            font-weight: 600;
            justify-content: space-between;
            margin-bottom: $spacer-1;


            span {
                align-items: center;
                display: flex;
                gap: $spacer-05;
                text-transform: uppercase;
            }
        }

        .token-item {
            cursor: pointer;
            display: flex;
            font-weight: 600;
            padding: $spacer-1;

            .description {
                flex: 1;

                .tokens-required {
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .icon-token {
                        color: $default-2;
                    }
                }
            }

            .amount {
                align-items: center;
                border: 1px solid $default-4;
                border-radius: 50%;
                display: flex;
                height: $spacer-3;
                justify-content: center;
                width: $spacer-3;
            }

            .actions {
                align-items: center;
                display: flex;
                gap: $spacer-05;
                justify-content: center;
            }

            &.selected {
                background: $success-2;
                color: $success-4;

                .amount {
                    background: $default-4;
                    color: $default-1;
                }

                &:not(.disabled) {

                    .tokens-required svg {
                        color: $default-3;
                    }
                }

                &.disabled {

                    .tokens-required svg {
                        color: $default-3;
                    }
                }
            }

            &:not(.selected) {

                .amount {
                    background: $default-6;
                    color: $default-2;
                }

                &:not(.disabled) {
                    background: $default-6;
                    color: $default-1;
                }

                &.disabled {
                    background: $default-5;
                    color: $default-3;

                    .tokens-required svg {
                        color: $default-3;
                    }
                }
            }
        }
    }
}
