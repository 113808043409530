@use "theme/variables" as *;

.c-promotions {
    background: $white;
    border: 1px solid $grey-6;
    box-shadow: $shadow-down-d;
    display: flex;
    max-width: 900px;
    position: relative;

    .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0 $spacer-1 * 1.5;

        .header {
            display: flex;
            flex-direction: column;
            margin-bottom: $spacer-1;
            padding-top: $spacer-1 * 1.5;

            .title {
                align-items: center;
                color: $default-2;
                display: flex;
                font-size: $font-xl;

                .c-icon {
                    margin-right: $spacer-05;
                }
            }

            .subtitle {
                font-weight: 600;

                &.grace-warning {
                    color: $warning-2;
                }
            }
        }

        > .description {
            flex: 1;
            line-height: $font-xl;
        }
    }

    .carousel-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        position: relative;

        .terms {
            background: hsla(var(--default-h) var(--s-3) var(--l-6) / 90%);
            color: $default-1;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 10;

            .btn-close {
                position: absolute;
                right: $spacer-1;
                top: $spacer-1;
                z-index: 1000;
            }

            .content {
                height: 100%;
                overflow-y: scroll;
                padding: $spacer-1;
                position: absolute;
                width: 100%;

                .title {
                    color: $default-2;
                    font-size: $font-l;
                    font-weight: 600;
                }

                .subtitle {
                    color: $info-1;
                }

                ul {
                    color: $default-1;
                    line-height: 1.7rem;
                    list-style: square;
                    padding: $spacer-2;
                }
            }
        }

        .c-carousel-giveaway {

            .c-carousel-slides {
                height: 500px;
            }
        }
    }

    @media (max-width: $breakpoint-10) {

        .promotion-info {

            .title {
                font-size: $font-l;
            }
        }

        .carousel-wrapper {

            .c-carousel-giveaway {

                .c-carousel-slides {
                    height: 300px;
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: $breakpoint-20) {
        flex-direction: column;
    }
}
