@use "theme/variables" as *;

.c-incentive-personal-offer {
    align-items: center;
    animation: show 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
    background: $info-2;
    color: $white;
    display: flex;
    font-style: italic;
    min-height: $spacer-8;
    opacity: 0;
    padding: $spacer-05;

    &.inactive {
        background: $grey-5;
        color: $grey-2;
    }

    .c-icon {
        margin-right: $spacer-05;
        min-width: $icon-xl;
    }

    .personal-text {
        display: flex;
        flex-direction: column;
        gap: $spacer-05;
    }
}
