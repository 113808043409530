@use "theme/variables" as *;

.c-checkout {

    .bar-checkout {
        // Somewhat similar in styling to c-bar-main,
        // but it is part of the scrollable view content.
        align-items: center;
        background: $success-4;
        border-bottom: 2px solid $default-4;
        display: flex;
        gap: $spacer-1;
        margin-bottom: $spacer-2;

        .bar-header {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: flex-start;

            .title {
                align-items: center;
                color: $success-2;
                display: flex;
                font-size: $font-xl;
                line-height: $spacer-8;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                white-space: nowrap;
            }

            .page-icon {
                align-items: center;
                background: $success-2;
                color: $success-5;
                display: flex;
                height: $spacer-8;
                justify-content: center;
                margin-right: var(--query-spacer);
                padding: $spacer-1;
                width: $panel-collapsed-width;
            }
        }
    }

    .no-issues {
        align-items: center;
        background: $success-2;
        color: $success-5;
        display: flex;
        gap: $spacer-1;
        justify-content: center;
        padding: $spacer-2 $spacer-4;
    }

    .vouchers {
        margin-bottom: $spacer-2;
    }

    .alert {
        margin-top: $spacer-2;
        margin-top: 0;
        width: 100%;

        button {
            margin-top: $spacer-1;
        }
    }

    .popover-question {
        margin-left: 24px;
    }
}

