@use "theme/variables" as *;

@keyframes blink {

    0%,
    100% {
        background-color: $success-4;
    }


    50% {
        background-color: $success-5;
    }
}

@keyframes scale-up {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}

.c-incentive.promotion {

    &.tokens-changed {
        animation: blink 0.5s ease-in-out 5;

        .c-icon {
            animation: scale-up 0.5s ease-in-out 5;
        }
    }
}
