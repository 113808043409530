@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.c-order-items {
    background: $white;
    width: 100%;

    .empty-cart {
        align-items: center;
        border-bottom: 2px dashed $grey-3;
        border-top: 2px dashed $grey-3;
        color: $grey-3;
        display: flex;
        flex-direction: column;
        font-weight: 600;
        justify-content: center;
        padding: $spacer-3 0;
        padding-left: $spacer-05;

        button.c-button.icon-order {
            cursor: default;

            &,
            &:hover {
                background: $grey-5;
                border-color: $grey-4;

                svg {
                    color: $grey-3;
                }
            }
        }
    }

    &.step-0 {

        .product-name {
            cursor: pointer;

            &:hover {
                color: $info-1;
            }
        }
    }

    @media (max-width: $breakpoint-10) {

        .product-line {
            // max-width: 100%;

            .product-name {
                max-width: 170px;
            }
        }
    }
}
