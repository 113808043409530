@use "theme/variables" as *;

.c-product-quantity {
    align-items: center;
    display: flex;

    // height: $spacer-8;
    justify-content: flex-end;

    .indicators {
        align-items: center;
        display: flex;
        justify-content: center;

        .btn {
            height: $spacer-3;
        }
    }

    .widget {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        .actions-extended {
            align-items: center;
            display: flex;
            max-width: 0;
            overflow: hidden;

            // Reserve space for the box-shadow of the button
            padding: $spacer-1;
            padding-right: 0;
            transition: max-width 0.3s ease;

            &.active {
                max-width: 100%;
            }
        }

        .btn-quantity {
            border-radius: 50%;
            margin: 0;
            padding: 0;
        }

        .input-amount {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 0 $spacer-05;
            min-width: auto;
            padding: 0;

            input {
                /* stylelint-disable-next-line property-no-vendor-prefix */
                -moz-appearance: textfield;
                border: 1px solid $default-3;
                border-radius: 50%;
                color: $info-1;
                font-size: $font-xs;
                height: $icon-xl;
                text-align: center;
                width: $icon-xl;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    /* stylelint-disable-next-line property-no-vendor-prefix */
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

        .actions {
            align-items: center;
            background: $grey-4;
            border: 1px solid $grey-5;
            border-top: 0;
            display: flex;
            justify-content: center;
            padding: 0;

            button {
                border: none;
                border-radius: 0;
                flex: 1;
            }
        }
    }

    &.less-available,
    &.min-quantity {

        .widget {

            .input-amount {

                input {
                    border: 1px solid $warning-3;
                    color: $warning-3;
                }
            }
        }
    }

    &.unavailable {

        .widget {

            .c-text,
            .btn-right {
                display: none;
            }
        }
    }

    .round-up-text {
        color: $grey-3;
        font-size: 11px;
    }
}
