@use "theme/variables" as *;

.c-spotlight-widget {
    margin-bottom: $spacer-3;

    .items {
        display: grid;
        gap: $spacer-3;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

        .item {
            align-items: center;
            background: $grey-6;
            border: 1px solid $surface-4;
            box-shadow: $shadow-down-l;
            cursor: pointer;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            padding: $spacer-025;
            transition: all 0.2s ease;

            &:hover {
                box-shadow: $shadow-down-d;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .product-info {
                background: $info-1;
                color: $info-6;
                display: flex;
                font-size: $font-l;
                text-align: center;
                width: 100%;

                .specs {
                    flex: 1;
                    font-size: $font-d;
                    padding: $spacer-05;

                    .excise {
                        font-size: $font-xs;
                    }
                }

                .c-changed {
                    background: $brand-3;
                    flex-direction: column;
                    justify-content: center;
                    width: $spacer-6 * 2;

                    .previous {
                        color: $brand-4;
                    }

                    .current {
                        color: $white;
                        font-size: $font-xl;
                    }
                }

                &:hover {
                    background: $info-2;
                }
            }

            .image {
                align-items: center;
                aspect-ratio: 4 / 3;
                display: flex;
                height: 200px;
                position: relative;
                text-align: center;
                width: 100%;

                .c-offer-price-ribbon {
                    align-items: center;
                    background: $brand-3;
                    color: $white;
                    display: flex;
                    font-size: $font-xs;
                    font-weight: 900;
                    justify-content: center;
                    left: -35px;
                    padding: $spacer-1;
                    position: absolute;
                    text-align: center;
                    top: 20px;
                    transform: rotate(-45deg);
                    width: 150px;
                }

                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }

            .product-image-container {
                border: 2px solid $info-2;
                border-radius: 50%;
                box-shadow: $shadow-down-d;
                height: 150px;
                margin: 8px 0;
                object-fit: cover;
                width: 150px;

                &.placeholder {
                    border: none;
                    box-shadow: none;

                    svg {
                        border: 2px solid $info-1;
                        border-radius: 50%;
                        box-shadow: $shadow-down-d;
                        height: 150px;
                        margin: 0;
                        object-fit: cover;
                        width: 150px;
                    }
                }
            }
        }
    }
}
