@use "theme/variables" as *;

.c-carousel.c-carousel-giveaway {

    .c-carousel-slides {

        .item {
            height: 100%;
            width: 100%;

            .slide-header {
                align-items: center;
                backdrop-filter: blur(2px);
                background: hsla(var(--info-h) var(--s-1) var(--l-1) / 50%);
                bottom: 0;
                color: $white;
                display: flex;
                flex-direction: column;
                height: 80px;
                justify-content: center;
                position: absolute;
                width: 100%;

                .title {
                    font-size: $font-xl;
                    margin-bottom: $spacer-025;
                }
            }

            .slide-subtitle {
                background: #f00;
            }

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }

    .body {
        padding: 0;
    }
}

