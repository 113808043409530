@use "theme/variables" as *;

.c-promotion-terms {
    color: $info-1;
    cursor: pointer;
    user-select: none;

    &:hover {
        color: $brand-3;
    }
}
