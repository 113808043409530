@use "theme/variables" as *;

.c-auth-login {

    .forgot-pw {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .otp-options {
        align-items: center;
        display: flex;
        gap: $spacer-2;
        justify-content: center;
    }
}
