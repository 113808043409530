@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.c-panel-cart {
    align-items: center;
    background: linear-gradient(to right, $default-1, $default-4);
    flex-direction: column;
    position: relative;

    .c-changed {
        flex-direction: row;
        font-size: $font-d;

        .current {
            font-size: $font-d;
        }
    }

    .content {

        .workflow {
            background: $success-5;
            display: flex;
            min-height: $spacer-7;
            width: 100%;
        }

        .c-order-items {
            height: 100%;
            overflow-y: scroll;
        }
    }

    .step-help {
        align-items: center;
        background: $success-5;
        border-bottom: 2px solid $success-4;
        display: flex;
        font-style: italic;
        line-height: 1.3rem;
        padding: $spacer-1 $spacer-4;
        position: relative;

        svg {
            left: $spacer-05;
            position: absolute;
        }
    }

    .cart-empty {
        align-items: center;
        background: $default-6;
        border: 2px dashed $default-4;
        color: $default-3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacer-3 0;
        padding-left: $spacer-05;
    }

    .btn-confirm-step {
        margin-top: $spacer-2;
        width: 100%;
    }

    .confirmations {
        display: flex;
        flex-direction: column;

        // While being a panel at the right, space is needed for the
        // Tawk widget at the right bottom. See for yourself with:
        // MSI_TEST_TAWK=1 npm run start
        margin-bottom: $spacer-7;
        width: 100%;

        .acknowledge {
            background: $default-5;
            border-bottom: 1px solid $default-4;
            border-top: 1px solid $default-4;
            margin-top: $spacer-1;
            padding: $spacer-1;

            .field {
                color: $info-6 !important;
                margin-bottom: $spacer-2;
            }
        }

        .confirmation {
            flex-direction: column;
            max-height: 0;
            overflow: hidden;
            padding: $spacer-05 $spacer-1;
            transition: max-height 0.3s ease;

            .field {
                margin-bottom: $spacer-2;

                &.c-radio-group {

                    .help {
                        // The help context needs more space, so we use regular
                        // document flow instead of absolute positioning.
                        bottom: unset;
                        margin: 0;
                        overflow: unset;
                        position: relative;
                        white-space: normal;
                    }
                }
            }

            &.active {
                max-height: 500px;
            }
        }
    }

    .error-quantity {
        margin-bottom: $spacer-2;
    }

    input[type="radio"] {
        margin-bottom: $spacer-05;
    }

    .checkbox {
        margin-top: $spacer-2;
    }

    h5 {
        margin-top: 0;
    }

    .confirm-cart-help {
        align-items: center;
        background: $warning-5;
        color: $warning-1;
        display: flex;
        gap: $spacer-05;
        margin-top: $spacer-05;
        padding: $spacer-05;
    }

    &.collapsed {

        .content {

            .c-order-items,
            .cart-empty {
                display: none;
            }
        }

        .c-changed {

            .old-new {
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }
        }

        .incentives {
            margin-bottom: $spacer-1;

            .text,
            .c-field-select {
                display: none;
            }

            .c-incentive {
                justify-content: center;

                .incentive-header {
                    margin-right: 0;
                }

                .c-icon {
                    margin-right: 0;
                }
            }
        }

        .vouchers {
            align-items: center;

            .item {
                display: flex;
                flex-direction: column;
            }

            button {
                height: auto;
                margin-top: 0;
                padding: $spacer-1;

                svg {
                    @include square($icon-s);
                }


                span {
                    display: none;
                }
            }
        }

        .confirmations {
            display: none;
            flex: 1;

            * {
                display: none;
            }
        }
    }

    &:not(.collapsed) {

        .workflow {
            padding: 0 $spacer-3;
        }
    }
}
