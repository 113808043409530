@use "theme/variables" as *;

.c-order-list {

    .c-collection-view .c-offer-item-amount {
        font-size: $font-s;
        font-weight: 300;
    }
}

.c-order-history {

    .c-items-view {

        .row-body {

            .column {
                padding-bottom: $spacer-1;
                padding-top: $spacer-1;
            }
        }

        .row:not(.row-header),
        .row:not(.row-footer) {
            // Clicking a row opens an order.
            cursor: pointer;
        }
    }

    @media (max-width: $breakpoint-35) {

        .c-items-view {

            .row {

                .column {

                    &.column-invoice-date,
                    &.column-subtotal,
                    &.column-date,
                    &.column-created {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: $breakpoint-20) {

        .c-items-view {

            .row {

                .column {

                    &.column-invoice-number {
                        display: none;
                    }
                }
            }
        }
    }
}
