@use "theme/variables" as *;

.c-cart-errors {
    background: $white;
    display: flex;
    flex-direction: column;
    gap: $spacer-1;
    width: 100%;

    .category {
        border-bottom: 1px solid;
        padding: 0 $spacer-1;
        padding-bottom: $spacer-05;

        .header {
            align-items: center;
            color: $default-1;
            display: flex;
            font-weight: 600;
            gap: $spacer-05;
            margin-bottom: $spacer-05;
            margin-top: $spacer-1;
            text-transform: uppercase;
        }

        .body {

            .issue {
                display: flex;

                .product-name {
                    flex: 1;
                    font-size: $font-s;
                    padding-left: $spacer-05;
                }

                .amount {
                    align-items: center;
                    color: $info-1;
                    display: flex;
                    font-weight: 600;
                }
            }

            .help {
                color: $default-3;
                display: flex;
                font-size: $font-s;
                font-style: italic;
                gap: $spacer-05;
            }
        }

        &.success {
            background: $success-5;
            border-color: $success-4;

            .category-header {
                color: $default-1;

                svg {
                    color: $success-3;
                }
            }

            .category-body .amount {
                color: $success-2;
            }
        }

        &.error {
            background: $danger-5;
            border-color: $danger-4;

            .category-header {
                color: $default-1;

                svg {
                    color: $danger-3;
                }
            }

            .category-body .amount {
                color: $danger-3;
            }
        }

        &.warning {
            background: $warning-5;
            border-color: $warning-4;

            .category-header {
                color: $default-1;

                svg {
                    color: $warning-3;
                }
            }

            .category-body .amount {
                color: $warning-3;
            }
        }
    }

    &.collapsed {
        flex: unset;

        .category {
            display: flex;
            height: $spacer-6;
            justify-content: center;
            padding: 0;

            .header {
                justify-content: center;
                margin: 0;

                span {
                    display: none;
                }
            }

            .body {
                display: none;
            }
        }
    }
}
