@use "theme/variables" as *;

.tip-container {
    padding: $spacer-1;
    position: relative;

    .tip-item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;

        .tip-icon {
            left: 0;
            opacity: 0.7;
            position: absolute;
            text-align: center;
            top: -10px;
            width: 100%;
            z-index: -1;
        }

        .tip-item-title {
            align-items: center;
            display: flex;
            font-size: $font-l;
            font-weight: bold;
            margin-bottom: 4px;

            svg {
                margin: 0;
                padding: 0;
            }
        }

        .tip-item-content {
            padding: 0 16px 0 0;
        }
    }
}

